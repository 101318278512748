/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

body {
    color: white;
    background: linear-gradient(#141e30, #243b55);
    background-attachment: fixed;
    overflow-x: hidden;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: rgba(27, 110, 194, 255);
    border-color: #1861ac;
}

.table,
.table tr td:nth-child(n) {
    color: white;
}

.table thead {
    background: #1861ac;
}

.table tr:nth-child(2n) {
    background: rgba(75, 75, 75, 0.62);
}
