@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

.Error{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button {
    font-weight: 300;
    color: #fff;
    font-size: 1.2em;
    text-decoration: none;
    border: 1px solid #efefef;
    padding: .5em;
    border-radius: 3px;
    float: left;
    margin: 6em 0 0 -155px;
    left: 50%;
    position: relative;
    transition: all .3s linear;
}

.button:hover {
    background-color: #007aff;
    color: #fff;
}

.para1 {
    font-size: 2em;
    text-align: center;
    font-weight: 100;
}

.ERR-HEAD {
    text-align: center;
    font-size: 15em;
    font-weight: 100;
    
}