a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.navbar-expand-sm {
    position: fixed;
    background: linear-gradient(#003a80, #1b6ec2);
    width: 100%;
    z-index: 1000; /* Stelle sicher, dass die Navbar über anderen Inhalten liegt */
    top: 0;
    height: 8vh;
}

.activeLink{
    color: #0dcaf0;
}


html {
    font-size: 14px;

}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(27, 110, 194, 1);
}

.Logo {
    width: 50px;
    height: 50px;
}
