.background{
    background-image: url("../IMGAGES/wolf-547203_1920.jpg");
    position: absolute;
    background-position: center;
    background-attachment: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow-x: hidden;
}

.Spaceblock1{
    height: 100vh;
}

.Head1{
    position: absolute;
    bottom: 10px;
    left: 15%;
    font-size: 100pt;
}